import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAllocations } from 'store/actions/allocations';
import {
    setClaimTransactionData,
    setClaimTransactionStatus,
} from 'store/actions/claim';
import { TransactionStatus } from 'types/api';

export interface ClaimState {
    transactionStatus: TransactionStatus;
    error: unknown;
    transaction: any;
}

const initialState: ClaimState = {
    transactionStatus: TransactionStatus.INITIAL,
    error: null,
    transaction: null,
};

export const claimSlice = createSlice<
    ClaimState,
    SliceCaseReducers<ClaimState>
>({
    name: 'claim',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setClaimTransactionData, (state, { payload }) => {
            state.transaction = {
                ...state.transaction,
                ...payload,
            };
        });

        builder.addCase(setClaimTransactionStatus, (state, { payload }) => {
            state.transactionStatus = payload;
        });
    },
});

export const claimReducer = claimSlice.reducer;
