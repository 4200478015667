import React, { memo, useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import {
    AptosWalletName,
    FewchaWalletName,
    MartianWalletName,
    NightlyWalletName,
    PontemWalletName,
    RiseWalletName,
} from '@mov3r/aptos-wallet-adapter';
import { useNavigate } from 'react-router';
import { Button } from 'components/Button';
import { useAppSelector } from 'store';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';

import { useEvmWalletContext } from 'utils/useEvmWalletContext';
import { useDispatch } from 'react-redux';
import {
    setCurrentWalletAddress,
    setSelectedBlockchain,
} from 'store/actions/user';
import { Blockhains } from 'types/enums';

import './Wallet.css';

const CnConnectWallet = cn('connectWallet');
const CnConnectWalletContent = cn('connectWalletContent');

const iconByAtposWalletName = {
    [PontemWalletName]: <Icons.Pontem />,
    [MartianWalletName]: <Icons.MartianAlt />,
    [FewchaWalletName]: <Icons.Fewcha />,
    [NightlyWalletName]: <Icons.Nightly />,
    [RiseWalletName]: <Icons.Rise />,
    [AptosWalletName]: <Icons.Petra />,
};

const iconByEthereumWalletName: Record<string, any> = {
    coinbaseWallet: <Icons.Coinbase />,
    metaMask: <Icons.Metamask />,
};

export const Wallet: React.FC = memo(() => {
    const navigate = useNavigate();
    const aptosWalletContext = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();

    const isAptosConnected = useMemo(
        () => !!aptosWalletContext.address,
        [aptosWalletContext.address],
    );
    const isEthereumConnected = useMemo(
        () => !!evmWallet.connected,
        [evmWallet.connected],
    );

    const connectWalletClickHandler = useCallback(() => {
        navigate('?modal=connectWallet');
    }, [navigate]);

    const WalletContent = useMemo(
        () => (
            <div
                onClick={connectWalletClickHandler}
                className={CnConnectWallet({ selected: true })}
            >
                <div className={CnConnectWallet('title')}>Connect wallet</div>
            </div>
        ),
        [],
    );

    return (
        <div className={CnConnectWallet('container')}>
            {isAptosConnected || isEthereumConnected ? (
                <>
                    <EthereumDropdownContent />
                    <AptosDropdownContent />
                </>
            ) : (
                WalletContent
            )}
        </div>
    );
});

interface IDropdownContentProps {}

const AptosDropdownContent: React.FC<IDropdownContentProps> = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const aptosWalletContext = useAptosWalletContext();
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );
    const isAptosSelected = useMemo(
        () => selectedBlockchain === 'aptos',
        [selectedBlockchain],
    );
    const isAptosConnected = useMemo(
        () => !!aptosWalletContext.address,
        [aptosWalletContext.address],
    );
    const [dropdownStyles, setDropdownStyles] = useState<any>({
        height: 0,
        padding: 0,
        overflow: 'hidden',
    });
    const [isDropdownShow, setIsDropdownShow] = useState(false);

    const copyClickCallback = useCallback(() => {
        if (aptosWalletContext.address) {
            navigator.clipboard.writeText(aptosWalletContext.address);
        }
    }, [aptosWalletContext.address]);

    const disconnectClickCallback = useCallback(async () => {
        setIsDropdownShow(false);
        setDropdownStyles({
            height: 0,
            padding: 0,
            overflow: 'hidden',
        });
        if (aptosWalletContext.disconnect) {
            await aptosWalletContext.disconnect();
            dispatch(setCurrentWalletAddress(null));
        }
    }, [aptosWalletContext]);

    const connectedWalletIcon = useMemo(() => {
        if (!isAptosSelected && !isAptosConnected) {
            return <Icons.Aptos />;
        }

        if (aptosWalletContext.adapter) {
            return iconByAtposWalletName[aptosWalletContext.adapter.name];
        }

        return null;
    }, [aptosWalletContext.adapter, isAptosSelected, isAptosConnected]);

    const isDropdownShowChangeCallback = useCallback(() => {
        if (isDropdownShow) {
            setTimeout(() => {
                setDropdownStyles({
                    height: 0,
                    padding: 0,
                    overflow: 'hidden',
                });
            }, 500);
        } else {
            setDropdownStyles({});
        }

        setIsDropdownShow((prev) => !prev);
    }, [isDropdownShow]);

    const connectWalletClickHandler = useCallback(() => {
        if (!isAptosConnected) {
            navigate('?modal=connectWallet');
        } else {
            if (!isAptosSelected) {
                window.localStorage.setItem('blockchain', Blockhains.Aptos);

                dispatch(setSelectedBlockchain(Blockhains.Aptos));
            } else {
                isDropdownShowChangeCallback();
            }
        }
    }, [
        dispatch,
        isAptosSelected,
        navigate,
        isAptosConnected,
        isDropdownShowChangeCallback,
    ]);

    const dropdownContent = useMemo(() => {
        return (
            <div
                style={dropdownStyles}
                className={CnConnectWallet('dropdown', {
                    show: isDropdownShow,
                })}
            >
                <div className={CnConnectWalletContent()}>
                    <div className={CnConnectWalletContent('top')}>
                        <div className={CnConnectWalletContent('header')}>
                            <div className={CnConnectWallet('left')}>
                                <div
                                    className={CnConnectWalletContent(
                                        'status',
                                        {
                                            connected:
                                                aptosWalletContext.connected,
                                        },
                                    )}
                                ></div>

                                <div
                                    className={CnConnectWalletContent(
                                        'address',
                                    )}
                                >
                                    {aptosWalletContext.formattedAddress}
                                </div>
                            </div>

                            <div className={CnConnectWalletContent('actions')}>
                                <Button
                                    onClick={copyClickCallback}
                                    view="icon"
                                    text={<Icons.Copy />}
                                />
                                <Button
                                    onClick={disconnectClickCallback}
                                    view="icon"
                                    text={<Icons.TurnOff />}
                                />
                            </div>
                        </div>

                        {/* <div className={CnConnectWalletContent('balance')}>
                            <div className={CnConnectWalletContent('apt')}>
                                {balance} APT
                            </div>
                            <div className={CnConnectWallet('usdc')}>
                                ${usdtBalance}
                            </div>
                        </div> */}
                    </div>
                    {/* <div className={CnConnectWalletContent('switch')}>
                        <Button view='default' text="Switch to Metamask"/>
                    </div> */}
                </div>
            </div>
        );
    }, [
        aptosWalletContext,
        isDropdownShow,
        copyClickCallback,
        disconnectClickCallback,
        dropdownStyles,
    ]);

    const walletContent = useMemo(() => {
        if (isAptosConnected || !isAptosSelected) {
            return (
                <>
                    <div className={CnConnectWallet('left')}>
                        {connectedWalletIcon}
                        <div className={CnConnectWallet('title')}>
                            {aptosWalletContext.formattedAddress}
                        </div>
                    </div>

                    <Icons.AngleDown
                        className={CnConnectWallet('opener', {
                            rotate: isDropdownShow,
                        })}
                    />
                </>
            );
        }

        return <div className={CnConnectWallet('title')}>Connect wallet</div>;
    }, [
        isAptosConnected,
        connectedWalletIcon,
        aptosWalletContext.formattedAddress,
        isDropdownShow,
        isAptosSelected,
    ]);

    return (
        <div
            className={CnConnectWallet('wrapper', {
                selected: isAptosSelected,
            })}
        >
            <div
                onClick={connectWalletClickHandler}
                className={CnConnectWallet({ connected: isAptosConnected })}
            >
                {walletContent}
            </div>
            {dropdownContent}
        </div>
    );
});

const EthereumDropdownContent: React.FC<IDropdownContentProps> = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const evmWallet = useEvmWalletContext();
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );
    const isEthereumSelected = useMemo(
        () => selectedBlockchain === 'ethereum',
        [selectedBlockchain],
    );
    const isEthereumConnected = useMemo(
        () => !!evmWallet.address,
        [evmWallet.address],
    );
    const [dropdownStyles, setDropdownStyles] = useState<any>({
        height: 0,
        padding: 0,
        overflow: 'hidden',
    });
    const [isDropdownShow, setIsDropdownShow] = useState(false);

    const copyClickCallback = useCallback(() => {
        if (evmWallet.address) {
            navigator.clipboard.writeText(evmWallet.address);
        }
    }, [evmWallet.address]);

    const disconnectClickCallback = useCallback(async () => {
        setIsDropdownShow(false);
        setDropdownStyles({
            height: 0,
            padding: 0,
            overflow: 'hidden',
        });
        if (evmWallet.disconnect) {
            await evmWallet.disconnect();
            dispatch(setCurrentWalletAddress(null));
        }
    }, [evmWallet]);

    const connectedWalletIcon = useMemo(() => {
        if (!isEthereumSelected && !isEthereumConnected) {
            return <Icons.Metamask />;
        }

        if (evmWallet?.connector?.id) {
            return iconByEthereumWalletName[evmWallet?.connector?.id];
        }

        return null;
    }, [isEthereumSelected, isEthereumConnected, evmWallet]);

    const isDropdownShowChangeCallback = useCallback(() => {
        if (isDropdownShow) {
            setTimeout(() => {
                setDropdownStyles({
                    height: 0,
                    padding: 0,
                    overflow: 'hidden',
                });
            }, 500);
        } else {
            setDropdownStyles({});
        }

        setIsDropdownShow((prev) => !prev);
    }, [isDropdownShow]);

    const connectWalletClickHandler = useCallback(() => {
        if (!isEthereumConnected) {
            navigate('?modal=connectWallet');
        } else {
            if (!isEthereumSelected) {
                window.localStorage.setItem('blockchain', Blockhains.Ethereum);

                dispatch(setSelectedBlockchain(Blockhains.Ethereum));
            } else {
                isDropdownShowChangeCallback();
            }
        }
    }, [
        dispatch,
        isEthereumConnected,
        navigate,
        isEthereumSelected,
        isDropdownShowChangeCallback,
    ]);

    const dropdownContent = useMemo(() => {
        return (
            <div
                style={dropdownStyles}
                className={CnConnectWallet('dropdown', {
                    show: isDropdownShow,
                })}
            >
                <div className={CnConnectWalletContent()}>
                    <div className={CnConnectWalletContent('top')}>
                        <div className={CnConnectWalletContent('header')}>
                            <div className={CnConnectWallet('left')}>
                                <div
                                    className={CnConnectWalletContent(
                                        'status',
                                        {
                                            connected: evmWallet.connected,
                                        },
                                    )}
                                ></div>

                                <div
                                    className={CnConnectWalletContent(
                                        'address',
                                    )}
                                >
                                    {evmWallet.formattedAddress}
                                </div>
                            </div>

                            <div className={CnConnectWalletContent('actions')}>
                                <Button
                                    onClick={copyClickCallback}
                                    view="icon"
                                    text={<Icons.Copy />}
                                />
                                <Button
                                    onClick={disconnectClickCallback}
                                    view="icon"
                                    text={<Icons.TurnOff />}
                                />
                            </div>
                        </div>

                        {/* <div className={CnConnectWalletContent('balance')}>
                            <div className={CnConnectWalletContent('apt')}>
                                {Number(balance).toFixed(4)}{' '}
                                {selectedToken.toUpperCase()}
                            </div>
                            <div className={CnConnectWallet('usdc')}>
                                ${usdtBalance}
                            </div>
                        </div> */}
                    </div>
                    {/* <div className={CnConnectWalletContent('switch')}>
                        <Button view='default' text="Switch to Metamask"/>
                    </div> */}
                </div>
            </div>
        );
    }, [
        evmWallet,
        isDropdownShow,
        copyClickCallback,
        disconnectClickCallback,
        dropdownStyles,
    ]);

    const walletContent = useMemo(() => {
        if (isEthereumConnected || !isEthereumSelected) {
            return (
                <>
                    <div className={CnConnectWallet('left')}>
                        <div className={CnConnectWallet('iconWrapper')}>
                            {connectedWalletIcon}
                        </div>
                        <div className={CnConnectWallet('title')}>
                            {evmWallet.formattedAddress}
                        </div>
                    </div>

                    <Icons.AngleDown
                        className={CnConnectWallet('opener', {
                            rotate: isDropdownShow,
                        })}
                    />
                </>
            );
        }

        return <div className={CnConnectWallet('title')}>Connect wallet</div>;
    }, [
        isEthereumConnected,
        connectedWalletIcon,
        evmWallet.formattedAddress,
        isDropdownShow,
        isEthereumSelected,
    ]);

    return (
        <div
            className={CnConnectWallet('wrapper', {
                selected: isEthereumSelected,
            })}
        >
            <div
                onClick={connectWalletClickHandler}
                className={CnConnectWallet({ connected: isEthereumSelected })}
            >
                {walletContent}
            </div>
            {dropdownContent}
        </div>
    );
});
