import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Schedule } from 'constants/allocationsConfig';
import {
    setAvailableForClaimAmount,
    setClaimedAmount,
    setCurrentAllocation,
    setCurrentAllocationSchedule,
    setCurrentWalletAddress,
    setSelectedAllocationType,
    setSelectedBlockchain,
    setCurrentStakingSchedule,
    setStakingFetchStatus,
    setYourAllocationFetchStatus,
    setIsClaimStarted,
} from 'store/actions/user';
import { Allocation, AllocationType } from 'types/allocation';
import { FetchStatus } from 'types/api';
import { Blockhains } from 'types/enums';
import { fromDecimals } from 'utils/decimals';

export interface UserState {
    error: unknown;
    selectedBlockchain: 'aptos' | 'ethereum';
    selectedAllocationType: AllocationType;
    address: null | string;
    allocation: null | Allocation;
    allocationSchedule: null | Schedule[];
    nextAllocation: null | Schedule;
    currAllocation: null | Schedule;
    claimed: null | number;
    availableClaim: null | number;
    yourAllocationFetchStatus: FetchStatus;
    isStaking: boolean;
    stakingSchedule: any;
    stakingFetchStatus: FetchStatus;
    stakingData: any;
    nextStaking: any;
    currStaking: any;
    isClaimStarted: boolean;
}

const initialState: UserState = {
    error: null,
    selectedBlockchain: Blockhains.Ethereum,
    selectedAllocationType: AllocationType.Ido,
    address: null,
    allocation: null,
    allocationSchedule: null,
    nextAllocation: null,
    currAllocation: null,
    claimed: null,
    availableClaim: null,
    yourAllocationFetchStatus: FetchStatus.INITIAL,
    isStaking: false,
    stakingFetchStatus: FetchStatus.INITIAL,
    stakingSchedule: null,
    stakingData: null,
    nextStaking: null,
    currStaking: null,
    isClaimStarted:
        window.localStorage.getItem('isClaimStarted') === 'true' ? true : false,
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>>({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setSelectedBlockchain, (state, { payload }) => {
            state.selectedBlockchain = payload;
        });

        builder.addCase(setIsClaimStarted, (state, { payload }) => {
            state.isClaimStarted = payload;
        });

        builder.addCase(setSelectedAllocationType, (state, { payload }) => {
            state.selectedAllocationType = payload;
            state.yourAllocationFetchStatus = FetchStatus.FETCHING;
            state.stakingFetchStatus = FetchStatus.FETCHING;
        });

        builder.addCase(setCurrentWalletAddress, (state, { payload }) => {
            if (payload) {
                state.address = payload?.toLowerCase();
            } else {
                state.address = null;
                state.allocation = null;
            }
        });

        builder.addCase(setCurrentAllocation, (state, { payload }) => {
            state.allocation = payload.allocation;
            state.selectedAllocationType = payload.selectedAllocationType;
        });

        builder.addCase(setClaimedAmount, (state, { payload }) => {
            state.claimed = payload;
        });

        builder.addCase(setAvailableForClaimAmount, (state, { payload }) => {
            if (payload) {
                state.availableClaim = fromDecimals(payload);
            } else {
                state.availableClaim = payload;
            }
        });

        builder.addCase(setYourAllocationFetchStatus, (state, { payload }) => {
            state.yourAllocationFetchStatus = payload;
        });

        builder.addCase(
            setCurrentStakingSchedule,
            (
                state,
                {
                    payload: {
                        isStakingStopped,
                        stakingSchedule,
                        data,
                        currStakingItem,
                        nextStakingItem,
                    },
                },
            ) => {
                state.isStaking = isStakingStopped;
                state.stakingSchedule = stakingSchedule;
                state.stakingFetchStatus = FetchStatus.FETCHED;
                state.stakingData = data;
                state.currStaking = currStakingItem;
                state.nextStaking = nextStakingItem;
            },
        );

        builder.addCase(setStakingFetchStatus, (state, { payload }) => {
            state.stakingFetchStatus = payload;
        });

        builder.addCase(
            setCurrentAllocationSchedule,
            (
                state,
                {
                    payload: {
                        allocationSchedule,
                        nextAllocation,
                        currAllocation,
                    },
                },
            ) => {
                state.allocationSchedule = allocationSchedule;
                state.nextAllocation = nextAllocation;
                state.currAllocation = currAllocation;
            },
        );
    },
});

export const userReducer = userSlice.reducer;
