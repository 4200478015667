import { cn } from '@bem-react/classname';
import {
    Allocation,
    NoAllocation,
    Header,
    Modal,
    Navigation,
} from 'components';
import { MobileModal } from 'components/MobileModal';
import {
    allocationsConfig,
    generateAllocationData,
    generateStakingData,
} from 'constants/allocationsConfig';
import { mover } from 'constants/token';
import {
    createContext,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchAllocations } from 'store/actions/allocations';
import {
    setAvailableForClaimAmount,
    setClaimedAmount,
    setCurrentAllocation,
    setCurrentAllocationSchedule,
    setCurrentStakingSchedule,
    setCurrentWalletAddress,
    setSelectedAllocationType,
    setYourAllocationFetchStatus,
} from 'store/actions/user';
import {
    Allocation as AllocationInterface,
    AllocationType,
} from 'types/allocation';
import { FetchStatus } from 'types/api';
import { Blockhains } from 'types/enums';
import { getAllocation } from 'utils/getAllocation';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './App.scss';

const CnApp = cn('app');

export const UpdateApplicationDataContext = createContext<any>({
    update: null,
});

export const App: React.FC = memo(() => {
    const dispatch = useAppDispatch();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();

    const address = useAppSelector((store) => store.user.address);

    const isNoConnectedWallet = useMemo(() => !address, [address]);

    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );

    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );

    const allocation = useAppSelector((store) => store.user.allocation);
    const allocations = useAppSelector(
        (store) => store.allocations.allocations,
    );

    useEffect(() => {
        if (!allocations) {
            dispatch(fetchAllocations());
        }
    }, []);

    const updateApplicationData = useCallback(async () => {
        if (selectedBlockchain === Blockhains.Aptos) {
            if (
                aptosWallet.address &&
                aptosWallet.getClaimInfo &&
                allocations
            ) {
                if (aptosWallet.address !== address) {
                    dispatch(setCurrentWalletAddress(aptosWallet.address));
                }

                const {
                    allocation,
                    selectedAllocationType: newSelectedAllocationType,
                } = getAllocation(
                    aptosWallet.address,
                    allocations,
                    selectedAllocationType,
                );

                if (!allocation) {
                    dispatch(
                        setCurrentAllocation({
                            allocation: null,
                            selectedAllocationType: AllocationType.Ido,
                        }),
                    );
                }

                if (allocation) {
                    dispatch(
                        setCurrentAllocation({
                            allocation,
                            selectedAllocationType,
                        }),
                    );

                    const contractAllocationType =
                        mover.getContractAllocationType(
                            newSelectedAllocationType,
                        );

                    const {
                        schedule,
                        claimed,
                        staking,
                        isStaking,
                        available,
                        stakingData,
                    } = await aptosWallet.getClaimInfo(
                        aptosWallet.address,
                        allocation.amount,
                        contractAllocationType,
                    );

                    dispatch(setClaimedAmount(claimed));

                    const allocationSchedule = generateAllocationData(
                        schedule,
                        newSelectedAllocationType,
                        allocation.amount,
                        allocation.signature,
                    );

                    const { stakingSchedule, currStaking, nextStaking } =
                        generateStakingData(staking);

                    dispatch(
                        setCurrentStakingSchedule({
                            isStakingStopped: isStaking,
                            stakingSchedule: stakingSchedule,
                            currStakingItem: currStaking,
                            nextStakingItem: nextStaking,
                            data: stakingData,
                        }),
                    );

                    dispatch(
                        setSelectedAllocationType(newSelectedAllocationType),
                    );
                    dispatch(setAvailableForClaimAmount(available));
                    dispatch(setCurrentAllocationSchedule(allocationSchedule));
                    dispatch(setYourAllocationFetchStatus(FetchStatus.FETCHED));
                }
            }
        } else {
            if (evmWallet.address && aptosWallet.getClaimInfo && allocations) {
                if (evmWallet.address !== address) {
                    dispatch(setCurrentWalletAddress(evmWallet.address));
                }

                const {
                    allocation,
                    selectedAllocationType: newSelectedAllocationType,
                } = getAllocation(
                    evmWallet.address,
                    allocations,
                    selectedAllocationType,
                );

                if (!allocation) {
                    dispatch(
                        setCurrentAllocation({
                            allocation: null,
                            selectedAllocationType: AllocationType.Ido,
                        }),
                    );
                }

                if (allocation) {
                    dispatch(
                        setCurrentAllocation({
                            allocation,
                            selectedAllocationType,
                        }),
                    );

                    const contractAllocationType =
                        mover.getContractAllocationType(
                            newSelectedAllocationType,
                        );

                    const {
                        schedule,
                        claimed,
                        staking,
                        isStaking,
                        available,
                        stakingData,
                    } = await aptosWallet.getClaimInfo(
                        evmWallet.address,
                        allocation?.amount as string,
                        contractAllocationType,
                    );

                    dispatch(setClaimedAmount(claimed));

                    const allocationSchedule = generateAllocationData(
                        schedule,
                        newSelectedAllocationType,
                        allocation.amount,
                        allocation.signature,
                    );

                    dispatch(
                        setSelectedAllocationType(newSelectedAllocationType),
                    );

                    const { stakingSchedule, currStaking, nextStaking } =
                        generateStakingData(staking);

                    dispatch(
                        setCurrentStakingSchedule({
                            isStakingStopped: isStaking,
                            stakingSchedule: stakingSchedule,
                            currStakingItem: currStaking,
                            nextStakingItem: nextStaking,
                            data: stakingData,
                        }),
                    );
                    dispatch(setAvailableForClaimAmount(available));
                    dispatch(setCurrentAllocationSchedule(allocationSchedule));
                    dispatch(setYourAllocationFetchStatus(FetchStatus.FETCHED));
                }
            }
        }
    }, [
        aptosWallet,
        evmWallet,
        selectedAllocationType,
        selectedBlockchain,
        allocations,
        address,
        dispatch,
    ]);

    useEffect(() => {
        updateApplicationData();
    }, [allocations, address, selectedAllocationType, selectedBlockchain]);

    const allocationContent = useMemo(
        () => (
            <div className={CnApp('box')}>
                <div className={CnApp('navigation')}>
                    <Navigation />
                </div>
                <div className={CnApp('content')}>
                    <Allocation />
                </div>
            </div>
        ),
        [],
    );

    const noAllocationContent = useMemo(() => <NoAllocation />, []);

    const allocationsFetchStatus = useAppSelector(
        (store) => store.allocations.fetchStatus,
    );

    let [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

    useEffect(() => {
        const resizeListener = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const appContent = useMemo(() => {
        if (isMobile) {
            return <MobileModal />;
        }

        if (!allocation) {
            if (allocationsFetchStatus === FetchStatus.FETCHING) {
                return <Skeleton width={'100%'} height={500} />;
            } else {
                return noAllocationContent;
            }
        } else {
            return allocationContent;
        }
    }, [
        allocationContent,
        noAllocationContent,
        allocation,
        allocationsFetchStatus,
        isMobile,
    ]);

    return (
        <UpdateApplicationDataContext.Provider
            value={{
                update: updateApplicationData,
            }}
        >
            <div className={CnApp()}>
                <Header hideAccount={isNoConnectedWallet} />
                {appContent}
                <Modal />
            </div>
        </UpdateApplicationDataContext.Provider>
    );
});
