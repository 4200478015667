import { cn } from '@bem-react/classname';
import { FC } from 'react';

import './ClaimBlocked.scss';

const CnClaimBlocked = cn('claimBlocked');

export const ClaimBlocked: FC = () => {
    return (
        <div className={CnClaimBlocked()}>
            Claiming is not available
            <br /> while staking is active
        </div>
    );
};
