import React, { useCallback, useEffect, useMemo } from 'react';

import {
    createClient,
    configureChains,
    useAccount,
    WagmiConfig,
    useConnect,
    Connector,
    useDisconnect,
    useSwitchNetwork,
    useNetwork,
    Chain,
    useSignMessage,
} from 'wagmi';
import { arbitrum, polygon, mainnet, bsc } from '@wagmi/chains';

import { infuraProvider } from 'wagmi/providers/infura';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { LedgerConnector } from 'wagmi/connectors/ledger';

import { IEvmWalletContext } from './EvmWalletProvider.types';
import { useNavigate } from 'react-router';
import {
    setCurrentWalletAddress,
    setSelectedBlockchain,
} from 'store/actions/user';
import { useAppDispatch, useAppSelector } from 'store';
import { Blockhains, Chains } from 'types/enums';
import { mover } from 'constants/token';

export const EvmWalletContext = React.createContext<IEvmWalletContext>({
    address: null,
    connectors: [],
    connect: null,
    connector: null,
    formattedAddress: '',
    connected: false,
    disconnect: null,
    switchNetwork: null,
    chainId: null,
    allocation: null,
    getEvmSignature: null,
});

const { chains, provider, webSocketProvider } = configureChains(
    [mainnet, polygon, bsc, arbitrum],
    [
        jsonRpcProvider({
            rpc: (chain: Chain) => {
                if (chain.id === Chains.Arbitrum) {
                    return {
                        http: 'https://arb1.arbitrum.io/rpc',
                    };
                }

                if (chain.id === Chains.Bsc) {
                    return {
                        http: 'https://bsc-dataseed.binance.org',
                    };
                }

                return null;
            },
        }),
        infuraProvider({
            apiKey: 'b9328e69231b48d695ae7912c2704d38',
        }),
    ],
);

export const evmClient = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new LedgerConnector({ chains }),
    ],
    provider,
    webSocketProvider,
});

interface IEvmWalletProvider {
    children: React.ReactNode;
}

const EvmWalletProviderContext: React.FC<IEvmWalletProvider> = ({
    children,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const account = useAccount();
    const { switchNetworkAsync } = useSwitchNetwork();
    const { chain } = useNetwork();
    const { connectAsync, connectors } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const address = useMemo(() => account.address, [account.address]) as string;
    const lowerCaseAddress = useMemo(
        () =>
            account.address ? account.address.toLowerCase() : account.address,
        [account.address],
    ) as string;
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );

    useEffect(() => {
        if (selectedBlockchain === Blockhains.Ethereum) {
            dispatch(setCurrentWalletAddress(address));
        }
    }, [address, selectedBlockchain]);

    const chainId = useMemo(() => (chain ? chain.id : null), [chain]);

    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );

    const allocations = useAppSelector(
        (store) => store.allocations.allocations,
    );

    const allocation = useMemo(
        () =>
            lowerCaseAddress && allocations
                ? allocations.claim[lowerCaseAddress]
                    ? allocations.claim[lowerCaseAddress][
                          selectedAllocationType
                      ]
                    : null
                : null,
        [allocations, lowerCaseAddress, selectedAllocationType],
    );

    const formattedAddress = useMemo(() => {
        if (address) {
            return `${address.slice(0, 9)}...${address.slice(
                address.length - 4,
            )}`;
        }

        return '';
    }, [address]);

    const switchNetwork = useCallback(
        async (chainId: number) => {
            if (switchNetworkAsync) {
                await switchNetworkAsync(chainId);
            }
        },
        [switchNetworkAsync],
    );

    const connect = useCallback(
        async (connector: Connector) => {
            try {
                const connectResponse = await connectAsync({ connector });
                window.localStorage.setItem('blockchain', Blockhains.Ethereum);
                dispatch(setSelectedBlockchain(Blockhains.Ethereum));
                dispatch(setCurrentWalletAddress(connectResponse.account));
                navigate('/');
            } catch (err: any) {
                if (
                    err?.name &&
                    err?.name?.includes('ConnectorNotFoundError')
                ) {
                    window.open(
                        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                        '_blank',
                    );
                }
            }
        },
        [dispatch, navigate, connectAsync],
    );

    const disconnect = useCallback(async () => {
        await disconnectAsync();
        dispatch(setCurrentWalletAddress(null));
        navigate('/');
    }, [navigate, dispatch, disconnectAsync]);

    const { signMessageAsync, isLoading } = useSignMessage();

    const getEvmSignature = useCallback(
        async (aptosAddress: string, timestamp: number, nonce: string) => {
            try {
                const message = mover.getSignatureMessage(
                    aptosAddress,
                    timestamp,
                    nonce,
                );

                const response = await signMessageAsync({ message });

                return response;
            } catch (err) {
                throw err;
            }
        },
        [signMessageAsync],
    );

    return (
        <EvmWalletContext.Provider
            value={{
                address,
                connectors,
                connect,
                connector: account.connector as Connector<any, any, any>,
                formattedAddress,
                connected: account.isConnected,
                disconnect,
                switchNetwork,
                chainId,
                allocation,
                getEvmSignature,
            }}
        >
            {children}
        </EvmWalletContext.Provider>
    );
};

export const EvmWalletProvider: React.FC<IEvmWalletProvider> = ({
    children,
}) => {
    return (
        <WagmiConfig client={evmClient}>
            <EvmWalletProviderContext>{children}</EvmWalletProviderContext>
        </WagmiConfig>
    );
};
