import React, { memo, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { AptosWalletProvider, EvmWalletProvider } from 'contexts';
import {
    AptosWalletAdapter,
    FewchaWalletAdapter,
    MartianWalletAdapter,
    NightlyWalletAdapter,
    PontemWalletAdapter,
    RiseWalletAdapter,
    WalletProvider,
} from '@mov3r/aptos-wallet-adapter';

interface IProvidersProps {
    children: any;
}

export const Providers: React.FC<IProvidersProps> = memo(({ children }) => {
    const wallets = useMemo(
        () => [
            new MartianWalletAdapter(),
            new PontemWalletAdapter(),
            new FewchaWalletAdapter(),
            new RiseWalletAdapter(),
            new NightlyWalletAdapter(),
            new AptosWalletAdapter(),
        ],
        [],
    );

    return (
        <WalletProvider
            wallets={wallets}
            autoConnect
            onError={(error: Error) => {
                let text = 'Unknow error';
                if (error.name === 'WalletNotReadyError') {
                    text = 'Wallet not ready';
                }
            }}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <AptosWalletProvider>
                        <EvmWalletProvider>{children}</EvmWalletProvider>
                    </AptosWalletProvider>
                </BrowserRouter>
            </Provider>
        </WalletProvider>
    );
});
