import { cn } from '@bem-react/classname';
import { Balance, Logo, Wallet } from 'components';
import { memo, useMemo } from 'react';

import './Header.scss';

const CnHeader = cn('header');

export const Header: React.FC<{ hideAccount?: boolean }> = memo(
    ({ hideAccount = false }) => {
        const accountContent = useMemo(() => {
            if (hideAccount) return null;

            return (
                <div className={CnHeader('account')}>
                    {/* <Balance /> */}
                    <Wallet />
                </div>
            );
        }, [hideAccount]);

        return (
            <div className={CnHeader()}>
                <Logo />

                {accountContent}
            </div>
        );
    },
);
