import { AllocationType } from 'types/allocation';

const contractTypeFromAllocationType = {
    [AllocationType.Ido]: '::claim::IDO',
    [AllocationType.Airdrop]: '::claim::Airdrop',
    [AllocationType.Adviser]: '::claim::Advisors',
    [AllocationType.Investors]: '::claim::Seed',
    [AllocationType.Team]: '::claim::Team',
    [AllocationType.Treasury]: '::claim::Treasury',
    [AllocationType.Community]: '::claim::Community',
};

export const mover = {
    decimals: 8,
    contract:
        '0x84bb7ac7ab1afc87921b7d5e58e1e563e6efd6a4e381225fdb829a27bb83aded',
    get withdrawFunction() {
        return `${this.contract}::claim::withdraw`;
    },
    get getNonceFunction() {
        return `${this.contract}::claim::get_nonce`;
    },
    get getAllocationsFunction() {
        return `${this.contract}::claim::get_unlocks_for_allocation`;
    },
    get getClaimedAmountFunction() {
        return `${this.contract}::claim::get_claimed_amount`;
    },
    get getAvailableForClaimFunction() {
        return `${this.contract}::claim::get_available_to_claim`;
    },
    get getClaimInfo() {
        return `${this.contract}::claim::get_all_claim_info`;
    },
    get stopStaking() {
        return `${this.contract}::claim::stop_staking`;
    },
    get getStakingData() {
        return `${this.contract}::claim::get_available_incl_staking`;
    },
    getContractAllocationType: function (allocationType: AllocationType) {
        const contractType = contractTypeFromAllocationType[allocationType];

        return this.contract + contractType;
    },
    token: '0x14b0ef0ec69f346bea3dfa0c5a8c3942fb05c08760059948f9f24c02cd0d4fd8::mover_token::Mover',
    getSignatureMessage: (address: string, timestamp: number, nonce: string) =>
        `I confirm that my Aptos address is: ${address}\nTimestamp: ${timestamp}\nNonce: ${nonce}\nURI: claim.mov3r.xyz`,
};
