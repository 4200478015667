import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './Logo.scss';

const CnLogo = cn('logo');

export const Logo: React.FC = () => {
    return (
        <div className={CnLogo()}>
            <Icons.Logo />
        </div>
    );
};
