import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Hover } from 'components/Hover';
import { Tooltip } from 'components/Tooltip';
import { Schedule } from 'constants/allocationsConfig';
import moment from 'moment';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { AllocationType } from 'types/allocation';
import { sliceDecimals } from 'utils/sliceDecimals';
import { truncateNumbers } from 'utils/truncateNumbers';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './AllocationSchedule.scss';

const CnAllocationSchedule = cn('allocationSchedule');

const textFromAllocationType = {
    [AllocationType.Ido]: '50% at TGE, 12.5% on a weekly basis',
    [AllocationType.Airdrop]: '10% at TGE, 10% on a weekly basis',
    [AllocationType.Adviser]:
        '0% at TGE, 1 month cliff, 2,78% unlock every month',
    [AllocationType.Investors]:
        '3% at the TGE, 3 months cliff, then 3.333% on a monthly basis',
    [AllocationType.Team]: '0% at TGE, 1 year cliff, 2,78% unlock every month',
    [AllocationType.Treasury]:
        '0% at TGE, 1 month cliff, unlocking every month 2,777%',
    [AllocationType.Community]:
        '0.8% at TGE, 1 month cliff, unlocking every month 2,755%',
};

export const AllocationSchedule: React.FC = () => {
    const allocationSchedule = useAppSelector(
        (store) => store.user.allocationSchedule,
    );

    const allocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );

    const allocationScheduleContent = useMemo(() => {
        if (!allocationSchedule) return null;

        return allocationSchedule.map((schedule) => (
            <AllocationScheduleItem key={schedule.iteration} {...schedule} />
        ));
    }, [allocationSchedule]);

    return (
        <div className={CnAllocationSchedule()}>
            <div className={CnAllocationSchedule('title')}>
                Allocation unlock schedule{' '}
                <Hover
                    key="allocationSchedule"
                    text={textFromAllocationType[allocationType]}
                    children={<Icons.Info />}
                />
            </div>
            <div className={CnAllocationSchedule('table')}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>BATCH</th>
                            <th style={{ width: '20%' }}>Allocation</th>
                            <th style={{ width: '20%' }}>PERcent</th>
                            <th style={{ width: '20%' }}>Date</th>
                        </tr>
                    </thead>

                    <tbody>{allocationScheduleContent}</tbody>
                </table>
            </div>
        </div>
    );
};

const AllocationScheduleItem: React.FC<Schedule> = (schedule) => {
    const isExpired = moment().isAfter(schedule.date);

    return (
        <tr className={CnAllocationSchedule('item', { expired: isExpired })}>
            <td>
                <span>{schedule.iteration}</span>
            </td>
            <td>
                <span>
                    {truncateNumbers(schedule.allocation.toFixed(2))} MOVER
                </span>
            </td>
            <td>
                <span>{sliceDecimals(schedule.percent, 2)}%</span>
            </td>
            <td>
                <span>{moment(schedule.date).format('DD MMM YYYY HH:mm')}</span>
                <Icons.Check className={CnAllocationSchedule('item-check')} />
            </td>
        </tr>
    );
};
