import { mover } from 'constants/token';
import BigNumber from 'bignumber.js';

export const fromDecimals = (amount: number) =>
    BigNumber(amount)
        .dividedBy(10 ** mover.decimals)
        .toNumber();

export const apyFromDecimals = (amount: number) =>
    BigNumber(amount)
        .dividedBy(10 ** 12)
        .toNumber();

export const toDecimals = (amount: number) =>
    BigNumber(amount)
        .multipliedBy(10 ** mover.decimals)
        .toNumber();
