import { cn } from '@bem-react/classname';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './NoAllocation.scss';

const CnNoAllocation = cn('noAllocation');

export const NoAllocation: React.FC = () => {
    const address = useAppSelector((store) => store.user.address);

    const isNoConnectedWallet = useMemo(() => !address, [address]);

    const navigate = useNavigate();

    const connectWalletClickCallback = useCallback(() => {
        navigate('?modal=connectWallet');
    }, [navigate]);

    const isNoConnectedWalletContent = useMemo(
        () => (
            <>
                <div className={CnNoAllocation('background')}></div>
                <div className={CnNoAllocation('title')}>
                    Connect the wallet to claim your MOVER tokens
                </div>
                <div className={CnNoAllocation('text')}>
                    You will see your allocations if you belong to one of the
                    eligible groups:
                    <br />
                    IDO participants,{' '}
                    <a className={CnNoAllocation('link')}>airdrop receivers</a>,
                    investors, team members.
                </div>

                <div
                    onClick={connectWalletClickCallback}
                    className={CnNoAllocation('button')}
                >
                    Connect wallet
                </div>
            </>
        ),
        [connectWalletClickCallback],
    );

    const isNoAllocationContent = useMemo(
        () => (
            <>
                <div className={CnNoAllocation('title')}>
                    You don’t have an allocation {':('}
                </div>
                <div className={CnNoAllocation('text')}>
                    Try another wallet or participate in the future events
                    <br /> to get MOVER tokens
                </div>

                <div
                    onClick={connectWalletClickCallback}
                    className={CnNoAllocation('button')}
                >
                    Connect another wallet
                </div>
            </>
        ),
        [connectWalletClickCallback],
    );

    const content = useMemo(() => {
        if (isNoConnectedWallet) return isNoConnectedWalletContent;

        return isNoAllocationContent;
    }, [
        isNoConnectedWallet,
        isNoConnectedWalletContent,
        isNoAllocationContent,
    ]);

    return (
        <div className={CnNoAllocation()}>
            <div className={CnNoAllocation('background')}></div>
            {content}
        </div>
    );
};
