import { cn } from '@bem-react/classname';
import { memo, useMemo } from 'react';
import { useAppSelector } from 'store';
import { fromDecimals } from 'utils/decimals';
import { getPercentFromNumbers } from 'utils/percent';
import { truncateNumbers } from 'utils/truncateNumbers';
import Skeleton from 'react-loading-skeleton';

import './YourAllocation.scss';
import { FetchStatus } from 'types/api';

const CnYourAllocation = cn('yourAllocation');

export const YourAllocation: React.FC = memo(() => {
    const allocation = useAppSelector((store) => store.user.allocation);
    const yourAllocationFetchStatus = useAppSelector(
        (store) => store.user.yourAllocationFetchStatus,
    );
    const amountInt = useMemo(
        () =>
            allocation?.amount ? fromDecimals(Number(allocation?.amount)) : 0,
        [allocation],
    );
    const truncatedAmount = useMemo(
        () => truncateNumbers(String(amountInt.toFixed(2))),
        [amountInt],
    );
    const claimed = useAppSelector((store) => store.user.claimed);

    const claimedInt = useMemo(
        () => (claimed ? fromDecimals(claimed) : 0),
        [claimed],
    );

    const claimedPercent = useMemo(
        () => getPercentFromNumbers(claimedInt, amountInt),
        [claimedInt, amountInt],
    );

    const remained = useMemo(
        () => (amountInt - claimedInt < 0 ? 0 : amountInt - claimedInt),
        [amountInt, claimedInt],
    );

    const truncatedRemained = useMemo(
        () => truncateNumbers(String(remained.toFixed(2))),
        [remained],
    );

    const remainedPercent = useMemo(
        () =>
            getPercentFromNumbers(Number(remained), amountInt) >= 100
                ? 100
                : getPercentFromNumbers(Number(remained), amountInt),
        [remained, amountInt],
    );

    const content = useMemo(() => {
        if (yourAllocationFetchStatus === FetchStatus.FETCHING) {
            return (
                <Skeleton
                    style={{ borderRadius: 12 }}
                    width={'100%'}
                    height={100}
                />
            );
        }

        return (
            <div className={CnYourAllocation('content')}>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Total allocation:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        {truncatedAmount} MOVER
                    </div>
                </div>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Already claimed:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        {claimedInt} MOVER ({claimedPercent.toFixed(2)}%)
                    </div>
                </div>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Total remaining:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        {truncatedRemained} MOVER ({remainedPercent.toFixed(2)}
                        %)
                    </div>
                </div>
            </div>
        );
    }, [
        claimedInt,
        claimedPercent,
        truncatedRemained,
        truncatedAmount,
        remainedPercent,
        yourAllocationFetchStatus,
    ]);

    return (
        <div className={CnYourAllocation()}>
            <div className={CnYourAllocation('title')}>Your allocation</div>
            {content}
        </div>
    );
});
