import { cn } from '@bem-react/classname';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { setSelectedAllocationType } from 'store/actions/user';
import { AllocationType } from 'types/allocation';
import { FetchStatus } from 'types/api';

import './Navigation.scss';

const CnNavigation = cn('navigation');

const navigationItems = [
    {
        text: 'IDO Allocation',
        allocationType: AllocationType.Ido,
    },
    {
        text: 'Airdrop',
        allocationType: AllocationType.Airdrop,
    },
    {
        text: 'Team',
        allocationType: AllocationType.Team,
    },
    {
        text: 'Investors',
        allocationType: AllocationType.Investors,
    },
    {
        text: 'Adviser',
        allocationType: AllocationType.Adviser,
    },
    {
        text: 'Treasury',
        allocationType: AllocationType.Treasury,
    },
    {
        text: 'Community & Growth',
        allocationType: AllocationType.Community,
    },
];

export const Navigation: React.FC = memo(() => {
    const dispatch = useDispatch();

    const yourAllocationFetchStatus = useAppSelector(
        (store) => store.user.yourAllocationFetchStatus,
    );

    const allocationsFetchStatus = useAppSelector(
        (store) => store.allocations.fetchStatus,
    );

    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );

    const address = useAppSelector((store) => store.user.address);

    const itemClickCallback = useCallback(
        (allocationType: AllocationType) => {
            if (allocationType !== selectedAllocationType) {
                if (
                    allocationsFetchStatus == FetchStatus.FETCHED &&
                    yourAllocationFetchStatus == FetchStatus.FETCHED
                ) {
                    dispatch(setSelectedAllocationType(allocationType));
                }
            }
        },
        [
            dispatch,
            selectedAllocationType,
            yourAllocationFetchStatus,
            allocationsFetchStatus,
        ],
    );

    const allocations = useAppSelector(
        (store) => store.allocations.allocations,
    );

    const navigationContent = useMemo(
        () =>
            navigationItems.map((item) => {
                const isSelected =
                    item.allocationType === selectedAllocationType;

                const isShow = allocations
                    ? allocations.claim[address ?? '']
                        ? allocations.claim[address ?? ''][item.allocationType]
                            ? 'block'
                            : 'none'
                        : 'none'
                    : 'none';

                return (
                    <div
                        style={{ display: isShow }}
                        onClick={() => itemClickCallback(item.allocationType)}
                        key={item.allocationType}
                        className={CnNavigation('item', {
                            selected: isSelected,
                        })}
                    >
                        {item.text}
                    </div>
                );
            }),
        [allocations, itemClickCallback, selectedAllocationType, address],
    );

    return <div className={CnNavigation()}>{navigationContent}</div>;
});
