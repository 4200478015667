import moment from 'moment';
import { AllocationType, UnlockIteration } from 'types/allocation';
import { apyFromDecimals, fromDecimals } from 'utils/decimals';

export const tgeDate = moment('2023-03-14 12:00:00+00');
export const tgeTimestamp = tgeDate.toDate().getTime();

export const allocationsConfig = {
    [AllocationType.Ido]: {
        initialUnlock: 0.5,
        unlockIteration: UnlockIteration.Week,
        unlockAmountPerIteration: 0.125,
        clifInMonths: 0,
        code: 0,
    },
    [AllocationType.Airdrop]: {
        initialUnlock: 0.1,
        unlockIteration: UnlockIteration.Week,
        unlockAmountPerIteration: 0.1,
        clifInMonths: 0,
        code: 1,
    },
    [AllocationType.Adviser]: {
        initialUnlock: 0,
        unlockIteration: UnlockIteration.Month,
        unlockAmountPerIteration: 0.0278,
        clifInMonths: 1,
        code: 2,
    },
    [AllocationType.Investors]: {
        initialUnlock: 0.03,
        unlockIteration: UnlockIteration.Month,
        unlockAmountPerIteration: 0.03333,
        clifInMonths: 3,
        code: 3,
    },
    [AllocationType.Team]: {
        initialUnlock: 0,
        unlockIteration: UnlockIteration.Month,
        unlockAmountPerIteration: 0.0278,
        clifInMonths: 12,
        code: 4,
    },
    [AllocationType.Treasury]: {
        initialUnlock: 0,
        unlockIteration: UnlockIteration.Month,
        unlockAmountPerIteration: 0.02777,
        clifInMonths: 1,
        code: 5,
    },
    [AllocationType.Community]: {
        initialUnlock: 0.008,
        unlockIteration: UnlockIteration.Month,
        unlockAmountPerIteration: 0.02755,
        clifInMonths: 1,
        code: 6,
    },
};

export interface Schedule {
    iteration: number;
    amount: number;
    allocation: number;
    percent: number;
    date: string;
    nextAllocationDate: string | null;
    signature: string;
    total: string;
}

export const getCurrAllocation = (allocations: Schedule[]): Schedule | null => {
    const now = moment();

    const currAllocation = allocations.find((allocation) => {
        const allocationDate = moment(allocation.date);
        const nextAllocationDate = moment(allocation.nextAllocationDate);

        if (allocationDate.isBefore(now) && nextAllocationDate.isAfter(now)) {
            return true;
        }

        if (
            allocationDate.isBefore(now) &&
            allocation.nextAllocationDate === null
        ) {
            return true;
        }

        return false;
    });

    return currAllocation ?? null;
};

export const getNextAllocation = (
    allocations: Schedule[],
    currAllocation: Schedule | null,
): Schedule | null => {
    let nextAllocation = null;
    const currAllocationIndex = allocations.findIndex(
        (allocation) => currAllocation?.iteration === allocation.iteration,
    );

    if (currAllocation) {
        if (currAllocationIndex === allocations.length) {
            nextAllocation = null;
        } else {
            if (currAllocationIndex === -1) {
                nextAllocation = null;
            } else {
                nextAllocation = allocations[currAllocationIndex + 1];
            }
        }
    } else {
        nextAllocation = allocations[0];
    }

    return nextAllocation ?? null;
};

export const generateAllocationData = (
    schedule: {
        amount: string;
        perc: string;
        time: string;
    }[],
    allocationType: AllocationType,
    allocationTotal: string,
    signature: string,
): {
    allocationSchedule: Schedule[];
    currAllocation: Schedule | null;
    nextAllocation: Schedule | null;
} => {
    const allocationSchedule: Schedule[] = [];
    let iteration = 1;

    for (let index = 0; index < schedule.length; index++) {
        const scheduleItem = schedule[index];
        const amount = Number(scheduleItem.amount);
        const percent = Number(scheduleItem.perc);
        const date = moment.unix(Number(scheduleItem.time));
        const nextItem = index < schedule.length ? schedule[index + 1] : null;
        const nextDate = nextItem ? moment.unix(Number(nextItem.time)) : null;

        allocationSchedule.push({
            iteration,
            amount,
            allocation: fromDecimals(amount),
            percent: fromDecimals(percent),
            date: date.format('YYYY-MM-DD HH:mm:ss'),
            nextAllocationDate: nextDate
                ? nextDate?.format('YYYY-MM-DD HH:mm:ss')
                : null,
            signature,
            total: allocationTotal,
        });

        iteration += 1;
    }

    const currAllocation = getCurrAllocation(allocationSchedule);
    const nextAllocation = getNextAllocation(
        allocationSchedule,
        currAllocation,
    );

    return {
        allocationSchedule,
        currAllocation,
        nextAllocation,
    };
};

interface StakingSchedule {
    apy: string;
    duration: string;
}

export const getCurrStaking = (schedule: Array<any>) => {
    const now = moment();

    const currItem = schedule.find((item) => {
        const stakingDate = moment.unix(item.date);
        const nextStakingDate = moment.unix(item.nextStakingDate);

        if (stakingDate.isBefore(now) && nextStakingDate.isAfter(now)) {
            return true;
        }

        if (stakingDate.isBefore(now) && item.nextStakingDate === null) {
            return true;
        }

        return false;
    });

    return currItem ?? null;
};

export const getNextStaking = (
    stakingSchedule: any[],
    currStaking: any | null,
) => {
    let nextStaking = null;
    const currStakingIndex = stakingSchedule.findIndex(
        (stakingItem) => currStaking?.index === stakingItem.index,
    );

    if (currStaking) {
        if (currStakingIndex === stakingSchedule.length) {
            nextStaking = null;
        } else {
            if (currStakingIndex === -1) {
                nextStaking = null;
            } else {
                nextStaking = stakingSchedule[currStakingIndex + 1];
            }
        }
    } else {
        nextStaking = stakingSchedule[0];
    }

    return nextStaking ?? null;
};

export const generateStakingData = (schedule: Array<StakingSchedule>) => {
    const sortedSchedule = schedule;

    const result = [];

    for (let index = 0; index < sortedSchedule.length; index++) {
        const scheduleItem = sortedSchedule[index];
        const apy = apyFromDecimals(Number(scheduleItem.apy));
        const nextItem =
            index < sortedSchedule.length ? sortedSchedule[index + 1] : null;
        const nextDate = nextItem ? nextItem.duration : null;

        result.push({
            index,
            apyDecimals: scheduleItem.apy,
            apy,
            percent: apy * 100,
            duration: scheduleItem.duration,
            date: scheduleItem.duration,
            nextStakingDate: nextDate,
        });
    }

    const currStaking = getCurrStaking(result);
    const nextStaking = getNextStaking(result, currStaking);

    return {
        stakingSchedule: result,
        currStaking,
        nextStaking,
    };
};
