import { memo, useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Button, Input, Select, Tooltip } from 'components';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';
import { Blockhains } from 'types/enums';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';
import { truncateNumbers } from 'utils/truncateNumbers';
import { allocationsConfig } from 'constants/allocationsConfig';
import { fromDecimals } from 'utils/decimals';
import {
    setClaimTransactionData,
    setClaimTransactionStatus,
} from 'store/actions/claim';
import { useDispatch } from 'react-redux';
import { TransactionStatus } from 'types/api';

import './Claim.scss';
import { mover } from 'constants/token';
import { sliceDecimals } from 'utils/sliceDecimals';

const CnClaim = cn('claim');

export const Claim: React.FC = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const currAllocation = useAppSelector((store) => store.user.currAllocation);
    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );
    const isStaking = useAppSelector((store) => store.user.isStaking);
    const availableClaim = useAppSelector((store) => store.user.availableClaim);
    const currAmount = useMemo(
        () =>
            availableClaim !== null ? sliceDecimals(availableClaim, 2) : '0.00',
        [availableClaim],
    );
    const [amount, setAmount] = useState('');

    const isAptosWalletConnected = useMemo(
        () => aptosWallet.address,
        [aptosWallet.address],
    );

    const amountChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;

            if (!value.includes(' ')) {
                if (!isNaN(Number(value))) {
                    if (value.includes('.')) {
                        const splitted = value.split('.');
                        const decimals = splitted[splitted.length - 1].length;

                        if (decimals < 9) {
                            setAmount(value);
                        }
                    } else {
                        setAmount(value);
                    }
                }
            }
        },
        [],
    );

    const isClaimStarted = useAppSelector((store) => store.user.isClaimStarted);

    const isButtonDisabled = useMemo(() => {
        if (!isClaimStarted) {
            return true;
        }

        if (!Number(currAmount)) {
            return true;
        }

        if (!Number(amount)) {
            return true;
        }

        if (Number(amount) > Number(currAmount)) {
            return true;
        }
    }, [currAmount, amount, isClaimStarted]);

    const claim = useCallback(async () => {
        if (isButtonDisabled) {
            return;
        }

        try {
            dispatch(setClaimTransactionStatus(TransactionStatus.PENDING));
            dispatch(
                setClaimTransactionData({
                    amount: amount,
                }),
            );
            navigate('?modal=claimLoader');

            if (selectedBlockchain === Blockhains.Aptos) {
                if (aptosWallet.claim && currAllocation) {
                    await aptosWallet.claim(
                        amount,
                        currAllocation?.signature,
                        currAllocation?.total,
                        mover.getContractAllocationType(selectedAllocationType),
                    );
                }
            } else {
                if (
                    aptosWallet.claim &&
                    evmWallet.getEvmSignature &&
                    aptosWallet.address &&
                    currAllocation?.signature &&
                    evmWallet.address &&
                    aptosWallet.getNonce &&
                    currAllocation?.total
                ) {
                    const timestamp = moment().unix();
                    const nonce = await aptosWallet.getNonce(evmWallet.address);

                    const evmSignature = await evmWallet.getEvmSignature(
                        aptosWallet.address,
                        timestamp,
                        nonce,
                    );

                    if (evmSignature) {
                        await aptosWallet.claim(
                            amount,
                            currAllocation?.signature,
                            currAllocation?.total,
                            mover.getContractAllocationType(
                                selectedAllocationType,
                            ),
                            evmWallet.address,
                            evmSignature,
                            timestamp,
                            nonce,
                        );
                    }
                }
            }
            navigate('?modal=claimSuccess');
        } catch (err) {
            dispatch(setClaimTransactionStatus(TransactionStatus.INITIAL));
        } finally {
            setAmount('');
        }
    }, [
        currAllocation,
        amount,
        aptosWallet,
        evmWallet,
        selectedBlockchain,
        selectedAllocationType,
        dispatch,
        navigate,
    ]);

    const maxClickCallback = useCallback(() => {
        setAmount(currAmount);
    }, [currAmount]);

    const claimInputTooltips = useMemo(
        () => [<Tooltip onClick={maxClickCallback} value={1} title="MAX" />],
        [maxClickCallback],
    );

    const connectAptosWalletCallback = useCallback(() => {
        navigate('?modal=connectAptosWallet');
    }, [navigate]);

    const claimButtonContent = useMemo(() => {
        if (isStaking) {
            return (
                <Button
                    disabled
                    text="Claiming is not available while staking is active"
                ></Button>
            );
        }
        if (!isAptosWalletConnected) {
            return (
                <Button
                    onClick={connectAptosWalletCallback}
                    text="Connect aptos wallet"
                ></Button>
            );
        }

        return (
            <Button
                onClick={claim}
                disabled={isButtonDisabled}
                text="Claim"
            ></Button>
        );
    }, [
        claim,
        connectAptosWalletCallback,
        isButtonDisabled,
        isAptosWalletConnected,
        isStaking,
    ]);

    const headerContent = useMemo(
        () => (
            <div className={CnClaim('header')}>
                <div className={CnClaim('label')}>Available for claim now:</div>

                <div className={CnClaim('balance')}>
                    {truncateNumbers(currAmount)} MOVER
                </div>
            </div>
        ),
        [currAmount],
    );

    const leftContent = useMemo(
        () => (
            <Select
                items={[
                    {
                        title: 'Mover',
                        icon: <Icons.Mover />,
                        value: 'mover',
                    },
                ]}
            />
        ),
        [],
    );

    const inputContent = useMemo(
        () => (
            <Input
                leftContent={leftContent}
                value={amount}
                onChange={amountChangeCallback}
                tooltips={claimInputTooltips}
                placeholder="0.0"
                token="MOVER"
            />
        ),
        [leftContent, amount, amountChangeCallback, claimInputTooltips],
    );

    return (
        <div className={CnClaim()}>
            {headerContent}

            {inputContent}

            {claimButtonContent}
        </div>
    );
});
