import { ChangeNetworkModal } from 'components/ChangeNetworkModal';
import { ConnectAptosWalletModal } from 'components/ConnectAptosWalletModal';
import { ConnectEvmWalletModal } from 'components/ConnectEvmWalletModal';
import { ChooseBlockchainModal } from 'components/ChooseBlockchainModal';
import { ConnectWalletModal } from 'components/ConnectWalletModal';
import { ClaimLoaderModal } from 'components/ClaimLoaderModal';
import { ClaimSuccessModal } from 'components/ClaimSuccessModal';
import { WithdrawStakingModal } from 'components/WithdrawStakingModal';
import { StakingLoaderModal } from 'components/StakingLoaderModal';

export const modalTypes: Record<string, JSX.Element> = {
    connectAptosWallet: <ConnectAptosWalletModal />,
    connectEvmWalletModal: <ConnectEvmWalletModal />,
    changeNetwork: <ChangeNetworkModal />,
    chooseBlockchain: <ChooseBlockchainModal />,
    connectWallet: <ConnectWalletModal />,
    claimLoader: <ClaimLoaderModal />,
    claimSuccess: <ClaimSuccessModal />,
    withdrawStaking: <WithdrawStakingModal />,
    stakingLoader: <StakingLoaderModal />,
};
