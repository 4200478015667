import { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import './Select.css';

const CnSelect = cn('select');

interface ISelectProps {
    items: {
        value: string;
        icon: JSX.Element;
        title: string;
    }[];
    disabled?: boolean;
}

export const Select: React.FC<ISelectProps> = ({ items, disabled = false }) => {
    const itemsContent = useMemo(() => {
        return items.map((item) => (
            <div key={item.value} className={CnSelect('item')}>
                <div className={CnSelect('item-icon')}>{item.icon}</div>
                <div className={CnSelect('item-title')}>{item.title}</div>
            </div>
        ));
    }, [items]);

    return (
        <div className={CnSelect()}>
            <div className={CnSelect('content')}>{itemsContent}</div>
        </div>
    );
};
