import React, { memo, useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { useAppSelector } from 'store';
import { TransactionStatus } from 'types/api';
import { useNavigate } from 'react-router';
import { Icons } from 'assets';

import './ClaimLoaderModal.scss';
import { fromDecimals } from 'utils/decimals';

const CnClaimLoaderModal = cn('claimLoaderModal');

export const ClaimLoaderModal: React.FC = memo(() => {
    const navigate = useNavigate();
    const transaction = useAppSelector((store) => store.claim.transaction);
    const transactionStatus = useAppSelector(
        (store) => store.claim.transactionStatus,
    );

    const amount = useMemo(
        () => (transaction ? transaction?.amount : null),
        [transaction],
    );

    useEffect(() => {
        if (transactionStatus !== TransactionStatus.PENDING) {
            navigate('/');
        }
    }, [transactionStatus, navigate]);

    return (
        <div className={CnClaimLoaderModal()}>
            <div className={CnClaimLoaderModal('icon')}>
                <Icons.Loader />
            </div>
            <div className={CnClaimLoaderModal('title')}>
                Transaction in progress
            </div>
            {transaction && (
                <div className={CnClaimLoaderModal('text')}>
                    Your {amount} $MOVERs is on the way!
                </div>
            )}
        </div>
    );
});
