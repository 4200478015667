import { createContext, memo, useCallback, useEffect, useMemo } from 'react';
import { AptosClient } from 'aptos';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { compareNetwork } from 'utils/networkCompare';
import { useWallet, WalletName } from '@mov3r/aptos-wallet-adapter';
import { IAptosWalletContext } from './AptosWalletProvider.types';
import { Blockhains } from 'types/enums';
import { mover } from 'constants/token';
import { hexToBytes } from 'utils/hextToBytes';
import { AllocationType } from 'types/allocation';
import { allocationsConfig, tgeDate } from 'constants/allocationsConfig';
import {
    setAvailableForClaimAmount,
    setClaimedAmount,
    setCurrentStakingSchedule,
    setCurrentWalletAddress,
} from 'store/actions/user';
import { toDecimals } from 'utils/decimals';
import moment from 'moment';

const aptosClient = new AptosClient(process.env.REACT_APP_APTOS_NODE as string);

export const AptosWalletContext = createContext<IAptosWalletContext>({
    connect: null,
    address: null,
    disconnect: null,
    network: null,
    formattedAddress: '',
    adapter: null,
    connected: false,
    signTransaction: null,
    allocation: null,
    claim: null,
    getNonce: null,
    getAllocationSchedule: null,
    getClaimedAmount: null,
    getAvailableForClaimAmount: null,
    getClaimInfo: null,
    stopStaking: null,
});

interface IAptosWalletProviderProps {
    children: React.ReactNode;
}

export const AptosWalletProvider: React.FC<IAptosWalletProviderProps> = memo(
    ({ children }) => {
        const wallet = useWallet();
        const navigate = useNavigate();
        const dispatch = useAppDispatch();

        const address = useMemo(
            () => wallet.account?.address as string,
            [wallet.account],
        );

        const selectedBlockchain = useAppSelector(
            (store) => store.user.selectedBlockchain,
        );

        useEffect(() => {
            if (selectedBlockchain === Blockhains.Aptos) {
                dispatch(setCurrentWalletAddress(address));
            }
        }, [address, selectedBlockchain]);

        const allocation = useAppSelector((store) => store.user.allocation);

        const formattedAddress = useMemo(() => {
            if (address) {
                return `${address.slice(0, 9)}...${address.slice(
                    address.length - 4,
                )}`;
            }

            return '';
        }, [address]);

        const connect = useCallback(
            async (walletName: WalletName<string>) => {
                await wallet.connect(walletName);

                return wallet.account?.address as string;
            },
            [wallet],
        );

        const disconnect = useCallback(async () => {
            await wallet.disconnect();

            dispatch(setCurrentWalletAddress(null));
        }, [wallet, dispatch]);

        const signTransaction = useCallback(
            async (payload: any, isFromModal = false) => {
                await wallet.signAndSubmitTransaction(payload);

                if (isFromModal) {
                    navigate('?modal=transactionSuccess');
                }
            },
            [wallet, navigate],
        );

        const selectedNetwork = useAppSelector(
            (store) => store.user.selectedBlockchain,
        );

        useEffect(() => {
            if (wallet?.network?.chainId) {
                const isNeededNetwork = compareNetwork(wallet.network.chainId);

                if (!isNeededNetwork) {
                    navigate('?modal=changeNetwork');
                }
            }
        }, [wallet.network, navigate, selectedNetwork]);

        const getAllocationSchedule = useCallback(
            async (amount: string, allocationType: AllocationType) => {
                const [allocations] = await aptosClient.view({
                    function: mover.getAllocationsFunction,
                    type_arguments: [],
                    arguments: [
                        amount,
                        String(allocationsConfig[allocationType].code),
                    ],
                });

                return allocations;
            },
            [],
        );

        const getClaimedAmount = useCallback(
            async (address: string, allocationType: number) => {
                const [amount] = await aptosClient
                    .view({
                        function: mover.getClaimedAmountFunction,
                        type_arguments: [],
                        arguments: [address, String(allocationType)],
                    })
                    .catch((err) => ['0']);

                return amount;
            },
            [],
        );

        const getClaimInfo = useCallback(
            async (
                address: string,
                totalAllocation: string,
                allocationType: string,
            ) => {
                const [data, schedule]: any = await aptosClient.view({
                    function: mover.getClaimInfo,
                    type_arguments: [allocationType, mover.token],
                    arguments: [address, String(totalAllocation), String(0)],
                });

                const [staking] = await new Promise<any>((resolve) =>
                    resolve([
                        [
                            { apy: '00000000000', duration: '1677585600' },
                            { apy: '500000000000', duration: '1681387200' },
                            { apy: '750000000000', duration: '1686571200' },
                            { apy: '1500000000000', duration: '1694347200' },
                        ],
                    ]),
                );

                const {
                    already_claimed,
                    available_for_claim,
                    staking_stop_time,
                } = data;

                const stakingAllocationTypes = [
                    mover.getContractAllocationType(AllocationType.Ido),
                    mover.getContractAllocationType(AllocationType.Airdrop),
                ];

                const isStaking =
                    stakingAllocationTypes.includes(allocationType) &&
                    staking_stop_time === '0';

                return {
                    schedule,
                    claimed: already_claimed,
                    staking,
                    isStaking,
                    available: available_for_claim,
                    stakingData: data,
                };
            },
            [],
        );

        const getAvailableForClaimAmount = useCallback(
            async (
                address: string,
                totalAllocation: string,
                allocationType: number,
            ) => {
                const [amount] = await aptosClient
                    .view({
                        function: mover.getAvailableForClaimFunction,
                        type_arguments: [],
                        arguments: [
                            address,
                            String(totalAllocation),
                            String(allocationType),
                        ],
                    })
                    .catch((err) => ['0']);

                return amount;
            },
            [],
        );

        const getNonce = useCallback(async (evmAddress: string) => {
            const [nonce] = await aptosClient.view({
                function: mover.getNonceFunction,
                type_arguments: [],
                arguments: [evmAddress],
            });

            return Number(nonce) + 1;
        }, []);

        const stopStaking = useCallback(
            async (
                signature: string,
                totalClaimAmount: string,
                allocationType: string,
                evmWalletAddress?: string,
                evmWalletSignature?: string,
                timestamp?: number,
                nonce?: string,
            ) => {
                try {
                    let args: Array<any> = [];

                    if (
                        evmWalletAddress &&
                        evmWalletSignature &&
                        timestamp &&
                        nonce
                    ) {
                        args = [
                            evmWalletAddress,
                            totalClaimAmount,
                            hexToBytes(signature),
                            hexToBytes(evmWalletSignature),
                            timestamp,
                            nonce,
                        ];
                    } else {
                        args = [
                            address,
                            totalClaimAmount,
                            hexToBytes(signature),
                            [],
                            moment().unix(),
                            0,
                        ];
                    }

                    const claimPayload = {
                        function: mover.stopStaking,
                        type_arguments: [allocationType, mover.token],
                        arguments: args,
                    };

                    const { hash } = await wallet.signAndSubmitTransaction(
                        claimPayload as any,
                    );

                    if (hash) {
                        await aptosClient.waitForTransactionWithResult(hash);
                    }

                    dispatch(
                        setCurrentStakingSchedule({
                            stakingSchedule: null,
                            isStakingStopped: false,
                            data: null,
                            currStakingItem: null,
                            nextStakingItem: null,
                        }),
                    );
                } catch {}
            },
            [dispatch, address, wallet],
        );

        const claim = useCallback(
            async (
                amount: string,
                signature: string,
                totalClaimAmount: string,
                allocationType: string,
                evmWalletAddress?: string,
                evmWalletSignature?: string,
                timestamp?: number,
                nonce?: string,
            ) => {
                try {
                    let args: Array<any> = [];

                    if (
                        evmWalletAddress &&
                        evmWalletSignature &&
                        timestamp &&
                        nonce
                    ) {
                        args = [
                            evmWalletAddress,
                            totalClaimAmount,
                            hexToBytes(signature),
                            toDecimals(Number(amount)),
                            hexToBytes(evmWalletSignature),
                            timestamp,
                            nonce,
                        ];
                    } else {
                        args = [
                            address,
                            totalClaimAmount,
                            hexToBytes(signature),
                            toDecimals(Number(amount)),
                            [],
                            moment().unix(),
                            0,
                        ];
                    }

                    const claimPayload = {
                        function: mover.withdrawFunction,
                        type_arguments: [allocationType, mover.token],
                        arguments: args,
                    };

                    const { hash } = await wallet.signAndSubmitTransaction(
                        claimPayload as any,
                    );

                    if (hash) {
                        await aptosClient.waitForTransactionWithResult(hash);
                    }

                    if (evmWalletAddress) {
                        const { available, claimed } = await getClaimInfo(
                            evmWalletAddress,
                            totalClaimAmount,
                            allocationType,
                        );

                        dispatch(setClaimedAmount(claimed as number));
                        dispatch(
                            setAvailableForClaimAmount(available as number),
                        );
                    } else {
                        const { available, claimed } = await getClaimInfo(
                            address,
                            totalClaimAmount,
                            allocationType,
                        );

                        dispatch(setClaimedAmount(claimed as number));
                        dispatch(
                            setAvailableForClaimAmount(available as number),
                        );
                    }
                } catch (err: any) {
                    throw err;
                } finally {
                }
            },
            [address, wallet, getClaimInfo, dispatch],
        );

        return (
            <AptosWalletContext.Provider
                value={{
                    connect,
                    address,
                    disconnect,
                    network: wallet.network,
                    formattedAddress,
                    adapter: wallet.wallet?.adapter,
                    connected: wallet.connected,
                    signTransaction,
                    allocation,
                    claim,
                    getNonce,
                    getAllocationSchedule,
                    getClaimedAmount,
                    getAvailableForClaimAmount,
                    getClaimInfo,
                    stopStaking,
                }}
            >
                {children}
            </AptosWalletContext.Provider>
        );
    },
);
