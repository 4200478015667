import { cn } from '@bem-react/classname';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import './Hover.scss';

const CnHover = cn('hover');

var hoverTimeoutMap: Record<string, NodeJS.Timeout> = {};

interface IHoverProps {
    children?: any;
    text: any;
    key: string;
    orient?: 'top' | 'bottom';
}

export const Hover: FC<IHoverProps> = ({ children, text, orient = 'top' }) => {
    const [isHoverShow, setIsHoverShow] = useState(false);
    const baseRef = useRef<any>();
    const dropdownRef = useRef<any>();

    const onMouseEnter = useCallback(() => {
        setIsHoverShow(true);

        if (hoverTimeoutMap[text]) {
            clearTimeout(hoverTimeoutMap[text]);
        }
    }, [text]);

    const onMouseLeave = useCallback(() => {
        hoverTimeoutMap[text] = setTimeout(() => {
            setIsHoverShow(false);
        }, 1000);
    }, [text]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                baseRef.current &&
                !baseRef.current.contains(event.target)
            ) {
                setIsHoverShow(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, baseRef]);

    return (
        <div ref={baseRef} onMouseEnter={onMouseEnter} className={CnHover()}>
            <div
                ref={dropdownRef}
                onMouseLeave={onMouseLeave}
                className={CnHover('content', { orient, show: isHoverShow })}
            >
                {text}
            </div>
            {children}
        </div>
    );
};
