import React, { memo, useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { useAppSelector } from 'store';
import { FetchStatus, TransactionStatus } from 'types/api';
import { useNavigate } from 'react-router';
import { Icons } from 'assets';
import { fromDecimals } from 'utils/decimals';

import './StakingLoaderModal.scss';

const CnStakingLoaderModal = cn('stakingLoaderModal');

export const StakingLoaderModal: React.FC = memo(() => {
    const navigate = useNavigate();
    const stakingFetchStatus = useAppSelector(
        (store) => store.user.stakingFetchStatus,
    );

    useEffect(() => {
        if (stakingFetchStatus !== FetchStatus.FETCHING) {
            navigate('/');
        }
    }, [stakingFetchStatus, navigate]);

    return (
        <div className={CnStakingLoaderModal()}>
            <div className={CnStakingLoaderModal('icon')}>
                <Icons.Loader />
            </div>
            <div className={CnStakingLoaderModal('title')}>
                Transaction in progress
            </div>
        </div>
    );
});
