import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'App/App';
import { Providers } from 'Providers';

import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Providers>
            <App />
        </Providers>
    </React.StrictMode>,
);
