import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Hover } from 'components';
import { Button } from 'components/Button';
import { tgeDate } from 'constants/allocationsConfig';
import moment from 'moment';
import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';
import { fromDecimals } from 'utils/decimals';
import { getPercentFromNumber, getPercentFromNumbers } from 'utils/percent';
import { truncateNumbers } from 'utils/truncateNumbers';

import './Staking.scss';

const CnStaking = cn('staking');

export const Staking: React.FC = () => {
    const nextStaking = useAppSelector((store) => store.user.nextStaking);
    const currStaking = useAppSelector((store) => store.user.currStaking);

    return (
        <div className={CnStaking()}>
            <div className={CnStaking('header')}>
                Pre-claim staking: active
                <Hover
                    key="staking"
                    text={
                        <>
                            Your $MOVERs are already earning you money! You can
                            read more at{' '}
                            <a
                                target={'_blank'}
                                href="https://moverxyz.medium.com/mover-pre-staking-program-7f1cf6907ef0"
                                rel="noreferrer"
                            >
                                Medium
                            </a>
                        </>
                    }
                    orient="bottom"
                    children={<Icons.Warning />}
                />
            </div>
            <div className={CnStaking('content')}>
                <StakingBanner />

                <div className={CnStaking('apy')}>
                    <hr />
                    <div>
                        Your APY:{' '}
                        {nextStaking
                            ? nextStaking.percent === 0
                                ? '50'
                                : nextStaking.percent
                            : currStaking
                            ? currStaking.percent
                            : null}
                        %
                    </div>
                    <hr />
                </div>

                <StakingProgress />

                <div className={CnStaking('info')}>
                    <StakingInfo />
                    <StakingAmount />
                </div>
            </div>
        </div>
    );
};

const CnStakingBanner = cn('stakingBanner');

const StakingBanner: FC = memo(() => {
    return (
        <div className={CnStakingBanner()}>
            <div className={CnStakingBanner('icon')}>
                <Icons.Staking />
            </div>

            <div className={CnStakingBanner('content')}>
                <div className={CnStakingBanner('title')}>Congratulations!</div>
                <div className={CnStakingBanner('text')}>
                    You were selected to be a member of $MOVER pre-claim staking
                    program!
                    <br /> Relax, your $MOVERS are already working for you! Just
                    wait and your APY will grow up to 150%.
                </div>
            </div>
        </div>
    );
});

const CnStakingProgress = cn('stakingProgress');

const datePositionFromApy: any = {
    0: '0',
    0.5: '30',
    0.75: '60',
    1.5: '100',
};

const percentPositionFromApy: any = {
    0: '0',
    0.5: '15',
    0.75: '45',
    1.5: '80',
};

const filledPositionsFromApy: any = {
    0: {
        start: '0',
        end: '0',
    },
    0.5: {
        start: '0',
        end: '30',
    },
    0.75: {
        start: '30',
        end: '60',
    },
    1.5: {
        start: '60',
        end: '100',
    },
};

const StakingProgress: React.FC = () => {
    const stakingSchedule = useAppSelector(
        (store) => store.user.stakingSchedule,
    );

    const nextStaking = useAppSelector((store) => store.user.nextStaking);
    const currStaking = useAppSelector((store) => store.user.currStaking);

    const monthsContent = useMemo(
        () =>
            stakingSchedule
                ? stakingSchedule.map((item: any) => {
                      const position = datePositionFromApy[item.apy];
                      const now = moment();
                      const isChecked =
                          now.isAfter(moment.unix(item.date)) &&
                          now.isBefore(moment.unix(item.nextStakingDate));

                      if (item.apy === 0) {
                          return null;
                      }

                      return (
                          <div
                              className={CnStakingProgress('months-item', {
                                  [position]: true,
                                  checked: isChecked,
                              })}
                              data-content={moment
                                  .unix(item.date)
                                  .format('DD.MM.YYYY')}
                          ></div>
                      );
                  })
                : null,
        [stakingSchedule],
    );

    const percentContent = useMemo(
        () =>
            stakingSchedule
                ? stakingSchedule.map((item: any) => {
                      const position = percentPositionFromApy[item.apy];
                      const isChecked = nextStaking?.apy === item.apy;
                      if (item.apy === 0) {
                          return null;
                      }
                      return (
                          <div
                              className={CnStakingProgress('percents-item', {
                                  [position]: true,
                                  checked: isChecked,
                              })}
                              data-content={`${item.percent}%`}
                          ></div>
                      );
                  })
                : null,
        [stakingSchedule, nextStaking],
    );

    const progressWidth = useMemo(() => {
        if (!stakingSchedule) {
            return '0%';
        }

        if (!currStaking) {
            return '100%';
        }

        if (nextStaking) {
            const positions = filledPositionsFromApy[nextStaking.apy];
            const now = moment();

            let startDate = moment(tgeDate).subtract(2, 'w');

            if (currStaking?.date) {
                startDate = moment.unix(currStaking.date);
            }

            const endDate = moment.unix(nextStaking.date);

            const diffNow = now.diff(startDate, 'days');
            const diffEnd = endDate.diff(startDate, 'days');

            const percentOnCurr = getPercentFromNumbers(diffNow, diffEnd);

            const percentOnAll = getPercentFromNumber(
                percentOnCurr,
                positions.end - positions.start,
            );

            const result = Number(positions.start) + percentOnAll;

            if (result > 100) {
                return `100%`;
            }

            return `${result}%`;
        }
    }, [stakingSchedule, nextStaking, currStaking]);

    return (
        <div className={CnStakingProgress()}>
            <div className={CnStakingProgress('line')}>
                <div className={CnStakingProgress('months')}>
                    {monthsContent}
                </div>
                <div
                    style={{ width: progressWidth }}
                    className={CnStakingProgress('filled')}
                >
                    <div className={CnStakingProgress('filled-dot')}></div>
                </div>
                <div className={CnStakingProgress('percents')}>
                    {percentContent}
                </div>
            </div>
        </div>
    );
};

const CnStakingInfo = cn('stakingInfo');

const StakingInfo: React.FC = () => {
    const stakingData = useAppSelector((store) => store.user.stakingData);

    const staked = useMemo(
        () =>
            stakingData
                ? truncateNumbers(fromDecimals(stakingData.staked).toFixed(2))
                : null,
        [stakingData],
    );

    const alreadyEarned = useMemo(
        () =>
            stakingData
                ? truncateNumbers(
                      fromDecimals(
                          Number(stakingData.staked_plus_reward) -
                              Number(stakingData.staked),
                      ).toFixed(2),
                  )
                : null,
        [stakingData],
    );

    const earnAtTheEnd = useMemo(
        () =>
            stakingData
                ? truncateNumbers(
                      fromDecimals(stakingData.staked_plus_reward_next).toFixed(
                          2,
                      ),
                  )
                : null,
        [stakingData],
    );

    return (
        <div className={CnStakingInfo()}>
            <div className={CnStakingInfo('item')}>
                <div className={CnStakingInfo('title')}>in staking now:</div>
                <div className={CnStakingInfo('value')}>{staked} MOVER</div>
            </div>
            <div className={CnStakingInfo('item')}>
                <div className={CnStakingInfo('title')}>
                    you have already earned:
                </div>
                <div className={CnStakingInfo('value')}>
                    {alreadyEarned} MOVER
                </div>
            </div>
            <div className={CnStakingInfo('item')}>
                <div className={CnStakingInfo('title')}>
                    you will earn at the end of the period:
                </div>
                <div className={CnStakingInfo('value')}>
                    {earnAtTheEnd} MOVER
                </div>
            </div>
        </div>
    );
};

const CnStakingAmount = cn('stakingAmount');

const StakingAmount: React.FC = () => {
    const navigate = useNavigate();
    const stakingData = useAppSelector((store) => store.user.stakingData);
    const stakingSchedule = useAppSelector(
        (store) => store.user.stakingSchedule,
    );

    const isClaimStarted = useAppSelector((store) => store.user.isClaimStarted);

    const withdrawClickCallback = useCallback(() => {
        if (isClaimStarted) {
            navigate('?modal=withdrawStaking');
        }
    }, [navigate, isClaimStarted]);

    const totalAmount = useMemo(() => {
        if (!stakingData) return null;

        return truncateNumbers(
            fromDecimals(stakingData.staked_plus_reward_next).toFixed(2),
        );
    }, [stakingData]);

    const lastStakingDate = useMemo(() => {
        if (!stakingSchedule) return null;

        const lastItem = stakingSchedule[stakingSchedule.length - 1];

        return moment.unix(lastItem.date).format('DD.MM.YYYY');
    }, [stakingSchedule]);

    return (
        <div className={CnStakingAmount()}>
            <div className={CnStakingAmount('amount')}>
                <div className={CnStakingAmount('title')}>
                    {totalAmount} MOVER
                </div>
                <div className={CnStakingAmount('description')}>
                    you will be able to withdraw on {lastStakingDate}
                </div>
            </div>
            <div className={CnStakingAmount('action')}>
                <div className={CnStakingAmount('label')}>
                    If you stop staking you will lose the MOVERs earned.
                    <br /> You will also lose access to staking program forever.
                </div>
                <div>
                    <Button
                        disabled={!isClaimStarted}
                        onClick={withdrawClickCallback}
                        view="bordered"
                        size="s"
                        text={'Withdraw from staking'}
                    />
                </div>
            </div>
        </div>
    );
};
