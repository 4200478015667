import { createAction } from '@reduxjs/toolkit';
import { TransactionStatus } from 'types/api';

export const setClaimTransactionStatus = createAction<TransactionStatus>(
    '@claim/setClaimTransactionStatus',
);

export const setClaimTransactionData = createAction<any>(
    '@claim/setClaimTransactionData',
);
