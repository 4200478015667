import { cn } from '@bem-react/classname';
import {
    TgeTimer,
    Claim,
    NextUnlock,
    YourAllocation,
    AllocationSchedule,
} from 'components';
import { ClaimBlocked } from 'components/ClaimBlocked';
import { Staking } from 'components/Staking';
import { useMemo } from 'react';
import { useAppSelector } from 'store';

import './Allocation.scss';

const CnAllocation = cn('allocation');

export const Allocation: React.FC = () => {
    const isStaking = useAppSelector((store) => store.user.isStaking);

    const stakingContent = useMemo(() => {
        if (!isStaking) return null;

        return (
            <div className={CnAllocation('row')}>
                <Staking />
            </div>
        );
    }, [isStaking]);

    const claimContent = useMemo(() => {
        if (isStaking)
            return (
                <div className={CnAllocation('row')}>
                    <ClaimBlocked />
                </div>
            );

        return (
            <div className={CnAllocation('row')}>
                <Claim />

                <NextUnlock />
            </div>
        );
    }, [isStaking]);

    return (
        <div className={CnAllocation()}>
            <div className={CnAllocation('row')}>
                <TgeTimer />
            </div>

            {stakingContent}

            {claimContent}

            <div className={CnAllocation('row')}>
                <YourAllocation />
            </div>

            <div className={CnAllocation('row')}>
                <AllocationSchedule />
            </div>
        </div>
    );
};
