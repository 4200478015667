import axios, { AxiosResponse } from 'axios';
import { Allocation } from 'types/allocation';

export const fetchAllocationsRequest = async () => {
    return await axios
        .get<
            never,
            AxiosResponse<{
                claim: Record<
                    string,
                    {
                        [key: string]: Allocation;
                    }
                >;
            }>
        >(`https://mov3r.github.io/database2.json`)
        .then((res) => res.data);
};
