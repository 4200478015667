import { cn } from '@bem-react/classname';
import './Balance.scss';

const CnBalance = cn('balance');

export const Balance: React.FC = () => {
    return (
        <div className={CnBalance('border')}>
            <div className={CnBalance()}>
                <div className={CnBalance('title')}>Already Claimed</div>
                <div className={CnBalance('value')}>0 MOVER</div>
            </div>
        </div>
    );
};
