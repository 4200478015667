import { cn } from '@bem-react/classname';
import { FC } from 'react';

import './MobileModal.scss';

const CnModal = cn('modal');
const CnMobileModal = cn('mobileModal');

export const MobileModal: FC = () => {
    return (
        <div className={CnMobileModal()}>
            <div className={CnMobileModal('background')}></div>
            <div className={CnMobileModal('title')}>
                Mobile not supported, please use desktop
            </div>
        </div>
    );
};
