import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './ClaimSuccessModal.css';

const CnClaimSuccessModal = cn('claimSuccessModal');

export const ClaimSuccessModal: React.FC = () => {
    return (
        <div className={CnClaimSuccessModal()}>
            <Icons.Success className={CnClaimSuccessModal('icon')} />

            <div className={CnClaimSuccessModal('title')}>Done!</div>
        </div>
    );
};
