import { combineReducers } from '@reduxjs/toolkit';
import { userReducer as user } from './user';
import { allocationsReducer as allocations } from './allocations';
import { claimReducer as claim } from './claim';

export const reducers = combineReducers({
    user,
    allocations,
    claim,
});
