import React, { memo, useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { useLocation, useNavigate } from 'react-router';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';
import { Connector } from 'wagmi';
import { useDispatch } from 'react-redux';
import { setSelectedBlockchain } from 'store/actions/user';
import { Blockhains, EthereumTokens } from 'types/enums';

import './ConnectEvmWalletModal.css';

const iconFromWalletId: Record<string, any> = {
    metaMask: <Icons.Metamask />,
};

const CnConnectEvmWalletModal = cn('connectEvmWalletModal');

export const ConnectEvmWalletModal: React.FC = memo(() => {
    const dispatch = useDispatch();
    const { connect, connectors } = useEvmWalletContext();
    const { search } = useLocation();
    const isShow = useMemo(
        () => search.includes('connectEvmWalletModal'),
        [search],
    );
    const navigate = useNavigate();

    const connectClickCallback = useCallback(
        (connector: Connector) => {
            return async () => {
                try {
                    if (connect) {
                        await connect(connector);
                        window.localStorage.setItem(
                            'blockchain',
                            Blockhains.Ethereum,
                        );

                        dispatch(setSelectedBlockchain(Blockhains.Ethereum));
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate('/');
                }
            };
        },
        [connect, navigate, dispatch],
    );

    const walletsContent = useMemo(
        () =>
            connectors.map((connector) => (
                <div
                    onClick={connectClickCallback(connector)}
                    key={connector.id}
                    className={CnConnectEvmWalletModal('wallet')}
                >
                    <div className={CnConnectEvmWalletModal('wallet-icon')}>
                        {iconFromWalletId[connector.id]}
                    </div>
                    <div className={CnConnectEvmWalletModal('wallet-title')}>
                        {connector.name}
                    </div>
                </div>
            )),
        [connectors, connectClickCallback],
    );

    return (
        <div className={CnConnectEvmWalletModal()}>
            <div className={CnConnectEvmWalletModal('title')}>
                Connect wallet
            </div>

            <div className={CnConnectEvmWalletModal('wallets')}>
                {isShow && walletsContent}
            </div>
        </div>
    );
});
