import React, { memo } from 'react';
import { cn } from '@bem-react/classname';

import './Button.scss';

const CnButton = cn('button');

interface IButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    text: any;
    view?: 'default' | 'disabled' | 'icon' | 'bordered' | 'dangerous';
    size?: 'm' | 's';
}

export const Button: React.FC<IButtonProps> = memo(
    ({ size = 'm', text, view = 'default', ...props }) => {
        return (
            <button className={CnButton({ view, size })} {...props}>
                {text}
            </button>
        );
    },
);
