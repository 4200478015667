import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { FC, useCallback } from 'react';

import './Checkbox.scss';

const CnCheckbox = cn('checkbox');

export const Checkbox: FC<{
    checked?: boolean;
    onClick?: (value: boolean) => void;
}> = ({ checked, onClick }) => {
    const checkClickCallback = useCallback(() => {
        if (onClick) {
            onClick(!checked);
        }
    }, [checked, onClick]);

    return (
        <div onClick={checkClickCallback} className={CnCheckbox({ checked })}>
            {checked && <Icons.Checkbox />}
        </div>
    );
};
