import { cn } from '@bem-react/classname';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { allocationsConfig } from 'constants/allocationsConfig';
import { mover } from 'constants/token';
import moment from 'moment';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';
import { setStakingFetchStatus } from 'store/actions/user';
import { FetchStatus } from 'types/api';
import { Blockhains } from 'types/enums';
import { fromDecimals } from 'utils/decimals';
import { truncateNumbers } from 'utils/truncateNumbers';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './WithdrawStakingModal.scss';

const CnWithdrawStakingModal = cn('withdrawStakingModal');

export const WithdrawStakingModal: FC = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const currAllocation = useAppSelector((store) => store.user.allocation);
    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );

    const nextStaking = useAppSelector((store) => store.user.nextStaking);
    const currStaking = useAppSelector((store) => store.user.currStaking);

    const stakingData = useAppSelector((store) => store.user.stakingData);

    const [isLoseChecked, setIsLoseChecked] = useState(true);

    const isLoseCheckedChangeCallback = useCallback(() => {
        setIsLoseChecked((prev) => !prev);
    }, []);

    const [isAccessChecked, setIsAccessChecked] = useState(false);

    const isAccessCheckedChangeCallback = useCallback(() => {
        setIsAccessChecked((prev) => !prev);
    }, []);

    const isButtonDisabled = useMemo(
        () => isLoseChecked && isAccessChecked,
        [isLoseChecked, isAccessChecked],
    );

    const stopStaking = useCallback(async () => {
        try {
            // dispatch(setClaimTransactionStatus(TransactionStatus.PENDING));
            // dispatch(
            //     setClaimTransactionData({
            //         amount: amount,
            //     }),
            // );
            dispatch(setStakingFetchStatus(FetchStatus.FETCHING));
            navigate('?modal=stakingLoader');

            if (selectedBlockchain === Blockhains.Aptos) {
                if (aptosWallet.stopStaking && currAllocation) {
                    await aptosWallet.stopStaking(
                        currAllocation?.signature,
                        currAllocation?.amount,
                        mover.getContractAllocationType(selectedAllocationType),
                    );
                }
            } else {
                console.log(
                    aptosWallet.address,
                    currAllocation?.signature,
                    evmWallet.address,
                    currAllocation?.amount,
                );

                if (
                    aptosWallet.stopStaking &&
                    evmWallet.getEvmSignature &&
                    aptosWallet.address &&
                    currAllocation?.signature &&
                    evmWallet.address &&
                    aptosWallet.getNonce &&
                    currAllocation?.amount
                ) {
                    const timestamp = moment().unix();
                    const nonce = await aptosWallet.getNonce(evmWallet.address);

                    const evmSignature = await evmWallet.getEvmSignature(
                        aptosWallet.address,
                        timestamp,
                        nonce,
                    );

                    if (evmSignature) {
                        await aptosWallet.stopStaking(
                            currAllocation?.signature,
                            currAllocation?.amount,
                            mover.getContractAllocationType(
                                selectedAllocationType,
                            ),
                            evmWallet.address,
                            evmSignature,
                            timestamp,
                            nonce,
                        );
                    }
                }
            }
            navigate('?modal=claimSuccess');
        } catch (err) {
        } finally {
            dispatch(setStakingFetchStatus(FetchStatus.FETCHED));
        }
    }, [
        currAllocation,
        aptosWallet,
        evmWallet,
        selectedBlockchain,
        selectedAllocationType,
        dispatch,
        navigate,
    ]);

    const keepEarning = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const canWithdrawNow = useMemo(() => {
        if (stakingData) {
            return truncateNumbers(
                fromDecimals(stakingData.available_for_claim).toFixed(2),
            );
        }

        return null;
    }, [stakingData]);

    const willEarn = useMemo(() => {
        if (stakingData) {
            return truncateNumbers(
                fromDecimals(stakingData.staked_plus_reward_next).toFixed(2),
            );
        }

        return null;
    }, [stakingData]);

    const nextStakingUnlock = useMemo(() => {
        if (nextStaking) {
            return moment.unix(nextStaking.date).format('DD.MM.YYYY');
        }

        return null;
    }, [nextStaking]);

    const looseContent = useMemo(() => {
        if (!stakingData || !currAllocation) return null;

        return truncateNumbers(
            fromDecimals(
                Number(stakingData.staked_plus_reward_next) -
                    Number(currAllocation.amount),
            ).toFixed(2),
        );
    }, [stakingData, currAllocation]);

    const connectAptosWallet = useCallback(() => {
        navigate('?modal=connectAptosWallet');
    }, [navigate]);

    const subtitleContent = useMemo(() => {
        if (!nextStaking) {
            const currStakingDate = moment.unix(currStaking.date);

            return (
                <>
                    If you withdraw before{' '}
                    {currStakingDate.format('DD MMMM YYYY')} you will lose all
                    the earnings accrued and won’t be able to stake anymore.
                </>
            );
        }

        if (nextStaking) {
            const nextStakingDate = moment.unix(nextStaking.date);

            return (
                <>
                    If you withdraw before{' '}
                    {nextStakingDate.format('DD MMMM YYYY')} you will lose all
                    the earnings accrued and won’t be able to stake anymore.
                </>
            );
        }

        return (
            <>
                If you withdraw before {null} you will lose all the earnings
                accrued and won’t be able to stake anymore.
            </>
        );
    }, [nextStaking]);

    const constantContent = useMemo(
        () => (
            <>
                <div className={CnWithdrawStakingModal('title')}>Warning</div>
                <div className={CnWithdrawStakingModal('subtitle')}>
                    {subtitleContent}
                </div>

                <div className={CnWithdrawStakingModal('amount')}>
                    <div className={CnWithdrawStakingModal('item')}>
                        <div className={CnWithdrawStakingModal('label')}>
                            You can withdrow now
                        </div>
                        <div className={CnWithdrawStakingModal('value')}>
                            {canWithdrawNow} MOVER
                        </div>
                    </div>
                    {nextStakingUnlock ? (
                        <div className={CnWithdrawStakingModal('item')}>
                            <div className={CnWithdrawStakingModal('label')}>
                                You can withdrow {nextStakingUnlock}
                            </div>
                            <div
                                className={CnWithdrawStakingModal('value', {
                                    blue: true,
                                })}
                            >
                                {willEarn} MOVER
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        ),
        [canWithdrawNow, willEarn, nextStakingUnlock, subtitleContent],
    );

    const checkboxContent = useMemo(
        () => (
            <div className={CnWithdrawStakingModal('terms')}>
                {/* <div
                    onClick={isLoseCheckedChangeCallback}
                    className={CnWithdrawStakingModal('check')}
                >
                    <Checkbox checked={isLoseChecked} />

                    <span>
                        I understand that I will lose earned {looseContent}{' '}
                        MOVER
                    </span>
                </div> */}

                <div
                    onClick={isAccessCheckedChangeCallback}
                    className={CnWithdrawStakingModal('check')}
                >
                    <Checkbox checked={isAccessChecked} />

                    <span>
                        I understand that I will lose access to pre-claim
                        staking forever
                    </span>
                </div>
            </div>
        ),
        [
            isAccessChecked,
            isLoseChecked,
            isLoseCheckedChangeCallback,
            isAccessCheckedChangeCallback,
            looseContent,
        ],
    );

    const actionsContent = useMemo(() => {
        return (
            <div className={CnWithdrawStakingModal('actions')}>
                <Button
                    onClick={keepEarning}
                    view="default"
                    size="s"
                    text="Keep earning"
                />
                {aptosWallet.address ? (
                    <Button
                        onClick={stopStaking}
                        disabled={!isButtonDisabled}
                        view="dangerous"
                        size="s"
                        text="Terminate forever"
                    />
                ) : (
                    <Button
                        onClick={connectAptosWallet}
                        disabled={!isButtonDisabled}
                        view="disabled"
                        size="s"
                        text="Connect aptos wallet"
                    />
                )}
            </div>
        );
    }, [
        stopStaking,
        keepEarning,
        isButtonDisabled,
        aptosWallet.address,
        connectAptosWallet,
    ]);

    return (
        <div className={CnWithdrawStakingModal()}>
            {constantContent}

            {checkboxContent}

            {actionsContent}
        </div>
    );
});
